$(window).scroll(function(){
    'use strict';
    if ($(this).scrollTop() > 1){
        $('header').addClass("sticky");
    }
    else if($('header').hasClass('home')){
        $('header').removeClass("sticky");
    }

    $('#horizontal-list li').on('click', function () {

        var id = $(this).attr('data-index');
        $(".video-slide").not('#video-slide' + id).animate({
            top: "600px"
        }, 500);
        $('#video-slide' + id).animate({
            top: 0
        }, 500);
        $('#horizontal-list li').removeClass("active");
        $(this).addClass("active");
        $('#youtube-player').html('');
        $('#youtube-player').hide();
        $("#thumb_container").animate({
            top: "562px"
        }, 200);
        $("#thumb_container ul#horizontal-list li").animate({
            width: "76px",
            height: "76px"
        }, 200);
        $("#thumb_container ul#horizontal-list li img").animate({
            width: "72px",
            height: "72px"
        }, 200);
    });

});

$(document).ready(function () {
    var maskList = $.masksSort($.masksLoad("/assets/web/js/plugins/inputmask-multi/data/phone-codes.json"), ['#'], /[0-9]|#/, "mask");
    var maskOpts = {
        inputmask: {
            definitions: {
                '#': {
                    validator: "[0-9]",
                    cardinality: 1
                }
            },
            showMaskOnHover: true,
            autoUnmask: false,
            clearMaskOnLostFocus: true,
            clearIncomplete: false,
            removeMaskOnSubmit: true
        },
        match: /[0-9]/,
        replace: '#',
        list: maskList,
        listKey: "mask"
    };
    $('.phone__mask').inputmask("remove");
    $('.phone__mask').inputmask(maskOpts);

    // Nav Scroll Click
    $("[data-smooth-scroll]").on("click", function (e) {
        e.preventDefault();
        var $this = $(this),
            time = $this.data('smooth-scroll') || 200,
            offset = $this.data('offset') || 0;

        $("html, body").animate({scrollTop: $($this.attr("href")).offset().top + offset + "px"}, time, "swing");
    });

    $('#js-btn-login').on('click', function(e) {
        e.preventDefault();

        $('#js-login-error').hide();

        var form = $('#js-form-login');

        $.ajax({
            method: "POST",
            url: '/login',
            data: form.serialize()
        }).done(function (data) {
            if(data.success) {
                window.location.replace(data.redirectUrl);
            } else {
               $('#js-form-login-error').html(data.error);
               $('#js-form-login-error').fadeIn();
            }
        });
    });

    $('#js-btn-reset-password').on('click', function(e) {
        e.preventDefault();

        $('#js-reset-password-msg').hide();

        var form = $('#js-form-reset-password');

        $.ajax({
            method: "POST",
            url: '/forgot-password',
            data: form.serialize()
        }).done(function (data) {
            if(data.success) {
                $('#js-reset-msg-success').html(data.msg);
            } else {
               $('#js-reset-password-msg').html(data.error);
            }
            $('#js-reset-password-msg').fadeIn();
        });
    });

    $('.js-forget-password-link').on('click', function(e) {
        $('#js-form-login-wrap').fadeOut('normal', function() {
            $('#js-reset-password-wrap').fadeIn();
        })
    });

    $('.js-signin-link').on('click', function(e) {
        $('#js-reset-password-wrap').fadeOut('normal', function() {
            $('#js-form-login-wrap').fadeIn();
        })
    });

    $(".mobileModal").on("click", function (e) {
        toggleFilter();
    });

    $(".selectpicker").selectpicker({
        liveSearch: true
    });

    $(".selectpicker-clear").on("click", function () {
        $(this).parent().find('.selectpicker').selectpicker('val', '');
        $(this).parent().find('.selectpicker').trigger('change');
        $(this).hide();
    });

    $('.selectpicker').on('changed.bs.select', function (e) {
        var val = e.target.value,
            name = e.target.name;

        if (val === '' || val === null)
            $('.selectpicker-clear[data-for="'+name+'"]').hide();
        else
            $('.selectpicker-clear[data-for="'+name+'"]').show();
    });

    $('.open[data-toggle-slide]').click();
    $('[data-toggle-slide]').on('click', function (e) {
        e.preventDefault();

        var el = $(this),
            target = $(el.data('toggle-slide'));

        target.slideToggle();
    });

    $('[data-counter]').each(function () {
        var time = $(this).data('counter') || 3000;

        $(this).prop('Counter',0).animate({
            Counter: $(this).text()
        }, {
            duration: time,
            easing: 'swing',
            step: function (now) {
                $(this).text(Math.ceil(now).toLocaleString());
            }
        });
    });
});

function show_video(id) {

    $("#thumb_container").animate({
        top: "622px"
    }, 200);
    $("#thumb_container ul#horizontal-list li").animate({
        width: "56px",
        height: "56px"
    }, 200);
    $("#thumb_container ul#horizontal-list li img").animate({
        width: "52px",
        height: "52px"
    }, 200);
    $('#youtube-player').html('<iframe width="100%" height="600" src="https://www.youtube.com/embed/' + id + '?autoplay=1" frameborder="0"></iframe> <div class="close_btn" onclick="close_video();">X</div>');
    $('#youtube-player').show();
}

function close_video() {
    $('#youtube-player').html('');
    $('#youtube-player').hide();
    $("#thumb_container").animate({
        top: "562px"
    }, 200);
    $("#thumb_container ul#horizontal-list li").animate({
        width: "76px",
        height: "76px"
    }, 200);
    $("#thumb_container ul#horizontal-list li img").animate({
        width: "72px",
        height: "72px"
    }, 200);

}

function toggleFilter() {
    $(".mobileFilterMenu").toggleClass("off on");
    $(".mobileModal").toggleClass("off on");
    $("body").toggleClass("mobileFilterOpen");
}

