var currentPage = 0;

function getFeatured() {
    $.ajax({
        method: "GET",
        url: '/influencers/featured',
        dataType: 'json'
    }).done(function (data) {
        $("#influencerFeaturedItems").append(data.html);
        window.innerWidth < 992 ? initFeaturedCarousel() : null;
        $("#influencerFeaturedParent").slideDown(300);
    });
}

function searchInfluencers(clearList) {
    $(".nextBtn").show();
    $(".nextBtn").html('<i class="la la-refresh la-spin"></i>');
    clearList === true ? $("#influencerListing").html('') : '';

    $.ajax({
        method: "GET",
        url: '/influencers/search?' + getFilters(),
        dataType: 'json'
    }).done(function (data) {
        currentPage = data.currentPage;
        currentPage > 1 ? $("#influencerListing").append(data.html) : $("#influencerListing").html(data.html);
        data.hasMore ? $('.nextBtn').show() : $('.nextBtn').hide();
        $("#js-total-records").html(data.totalRecords);
        $(".nextBtn").html('Load more');
    });
}

function getFilters() {
    var params = $('form#filter').serialize(),
        nextPage = currentPage + 1;

    params += '&page=' + nextPage;

    return params;
}

function initFeaturedCarousel () {

    if (window.jQuery('#influencerFeaturedItems').length > 0 && typeof window.jQuery('#influencerFeaturedItems').data('owl.carousel') === 'undefined') {
        var owl = window.jQuery('#influencerFeaturedItems');
        owl.find('.col-xs-6').removeClass('col-xs-6').addClass('col-xs-12')
        owl.addClass('owl-carousel')
        owl.owlCarousel({
            stagePadding: 0,
            smartSpeed: 450,
            loop: true,
            autoplay: false,
            slideBy: 2,
            autoplayTimeout: false,
            items: 2,
            margin: 15
        })
    }
}

$(document).ready(function () {

    $("form#filter").on("submit", function (e) {
        e.preventDefault();
    });

    $("form#filter input, form#filter select").on("change", function (e) {
        currentPage = 0;

        var search = $('form#filter input[name="search"]').val();
        if (typeof search !== "undefined")
            window.history.pushState({}, "", "?term=" + search)

        searchInfluencers(true);
    });

    $(".nextBtn").on("click", function (e) {
        searchInfluencers();
    });

    getFeatured();
    searchInfluencers();
});
