function initShowCaseCarousel () {
    if ($('#show-case').length > 0 && typeof $('#show-case').data('owl.carousel') === 'undefined') {
        var owl = $('#show-case');
        owl.addClass('owl-carousel')
        owl.owlCarousel({
            stagePadding: 0,
            smartSpeed: 450,
            loop: true,
            autoplay: false,
            slideBy: 2,
            autoplayTimeout: false,
            items: 1,
            margin: 0
        })
    }
}

function destroyShowCaseCarousel () {
    if ($('#show-case').length > 0 && typeof $('#show-case').data('owl.carousel') !== 'undefined') {
        $('#show-case').data('owl.carousel').destroy()
        $('#show-case').removeClass('owl-carousel')
    }
}

function handleShowCaseCarousel () {
    window.innerWidth >= 768 ? destroyShowCaseCarousel() : initShowCaseCarousel()
}


$(window).resize(function () {
    handleShowCaseCarousel();
})

$(document).ready(function () {

    handleShowCaseCarousel();

    $('#js-send-message').on('click', function(evt) {
        evt.preventDefault();
        var btn = $(this);

        btn.html('<i class="fa fa-refresh fa-spin"></i>');

        var id = btn.data('id');
        var form = $('#js-form-message');

        $.ajax({
            method: "POST",
            url: '/api/send-message',
            data: form.serialize(),
            success: function () {
                $('#js-msg-container').html('<i style="font-size: 48px; margin-bottom: 15px; color: #5cb85c;" class="fa fa-check-circle-o" aria-hidden="true"></i><br>Thanks! Your message has ben sent.');
                $('#js-msg-container').show();
                form.hide();
                $('#js-send-message').hide();
            },
            error: function () {
                $('#js-msg-container').html('<div class="alert alert-danger">Please, check the required fields.</div>');
                btn.html('SEND');
            }
        });
    })

});
